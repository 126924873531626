<template>
    <q-menu class="bg-grey-9 text-grey-3 py3" anchor="top right" self="top left" >
        <q-list style="min-width: 160px">
            <q-item clickable @click="goto_link(item)" 
                    v-for="(item, idx) in menu.children"
                    :key="keyIndex+'.'+idx" >
                <q-item-section>{{item.title}}</q-item-section>
                <template v-if="item.children.length>0">
                    <q-item-section side >
                        <q-icon name="keyboard_arrow_right" ></q-icon>
                    </q-item-section>
                    <admin-left-pop-menu :menu="item" :key-index="keyIndex+'.'+idx" ></admin-left-pop-menu>
                </template>
            </q-item>
        </q-list>
    </q-menu>
</template>

<script>
export default {
    name : 'admin-left-pop-menu',
    props : {
        menu : Object,
        keyIndex : String,
    },
    data : function() {
        return {}
    },
    methods : {
        goto_link : function(menu) {
            if(menu.children.length==0 && menu.link) {
                this.$c.goto_url(menu.link);
            }
        },
    },
};
</script>

