<template>
    <q-list class="text-grey-3 q-pt-sm">
        <template v-for="(menu, index) in user_menu" :key="index">
            <q-item class="left_menu pb2" :class="{active:menu.is_selected}"
                    clickable v-ripple @click="goto_link(menu)" >
                <div class="ft20">
                    <q-icon :name="menu.icon" />
                </div>
            </q-item>
        </template>
    </q-list>
</template>

<script>
export default {
    components : {
    },
    data() {
        return {
            user_menu : [
                    { title : '대시보드', icon : 'home', route:"/user/app/school/contents/dashboard", 
                        is_selected: false,
                        link : '/user/app/school/contents/dashboard', children: [] },
                    { title : '학습코스', icon : 'o_location_city', route:"/user/app/school/contents/course/list", is_selected: false,
                        link : '/user/app/school/contents/course/list', children: [] },
            ],
        }
    },
    computed : {
        user() {
            return this.$store.state.user;
        },
    },
    methods : {
        leftMenu_click : function(route) {
            var i;
            for(i=0; i<this.user_menu.length; i++) {
                if( route.startsWith(this.user_menu[i].route)) {
                    if( this.user_menu[i].is_selected==true ) {
                        this.user_menu[i].is_selected = false;
                    } else {
                        this.user_menu[i].is_selected = true;
                    }
                } else {
                    this.user_menu[i].is_selected = false;
                }
            }
        },
        goto_link : function(menu) {
            if(menu.children.length==0 && menu.link) {
                location.href = menu.link;
            }
        },
    },
    
    beforeMount() {
        this.leftMenu_click(window.location.pathname);
    },
}
</script>

<style scope>
.q-item__section--avatar {
    min-width: 40px;
}
.q-expansion-item.active {
    background-color:#333;
}
.q-expansion-item.left_menu > .q-expansion-item__container > .q-item {
    min-height:36px;
}
</style>
