<template>
    <q-btn flat :label="language_title">
        <q-menu :offset="[0, 10]" >
            <q-list style="min-width: 100px">
                <q-item clickable 
                        v-for="lang in language_list"
                        :key="lang.language_id"
                        @click="change_language(lang.language_id)" >
                    <q-item-section>{{lang.language_name}}</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
    <q-btn flat>
        <q-icon name="account_circle" class="mr2" ></q-icon>
        {{admin_name}}
        <q-menu :offset="[0, 10]" >
            <q-list style="min-width: 100px">
                <q-item clickable @click="click_logout" >
                    <q-item-section>Logout</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script>
export default {
    data : function() {
        return {
        }
    },
    computed :{
        language_list() {
            return this.$store.state.language_list;
        },
        language_id() {
            return this.$store.state.language_id;
        },
        language_title() {
            for(let i=0; i<this.language_list.length; i++) {
                if(this.language_id==this.language_list[i].language_id) {
                    return this.language_list[i].language_name;
                }
            }
            return '';
        },
        admin_name() {
            let admin = this.$store.state.admin;
            return (admin.a_name)? admin.a_name: admin.admin_name;
        },
    },
    methods : {
        change_language : function(language_id) {
            this.$c.io.post("/api/system/languages/"+language_id+"/set", {}).then(function(){
                location.reload();
            });
        },
        click_logout : function() {
            let vm = this;
            this.$c.io.post( '/api/account/admin/logout', {}).then(function() {
                vm.$c.goto_page('/admin/login');
            });
        },
    },
};
</script>