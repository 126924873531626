<template>
  <div>
    페이지가 없습니다.
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
}
</script>
