<template>
    <q-list class="text-grey-3 q-pt-sm">
        <template v-for="(menu, index) in admin_menu" :key="index">
            <q-expansion-item group="menu" class="left_menu pb2" expand-separator :class="{active:menu.is_selected}"
                            :icon="menu.icon" :label="menu.title" v-model="menu.is_selected"
                            @click="goto_link(menu)" >
                <q-item clickable v-ripple @click="goto_link(item)" 
                        v-for="(item, i) in menu.children" :key="index+'.'+i">
                    <q-item-section avatar></q-item-section>
                    <q-item-section> {{ item.title }} </q-item-section>
                    <template v-if="item.children.length>0">
                        <q-item-section side >
                            <q-icon name="keyboard_arrow_right" ></q-icon>
                        </q-item-section>
                        <admin-left-pop-menu :menu="item" :key-index="''+i" ></admin-left-pop-menu>
                    </template>
                </q-item>
            </q-expansion-item>
        </template>
    </q-list>
</template>

<script>
import adminLeftPopMenu from "./adminLeftPopMenu.vue"

export default {
    components : {
        adminLeftPopMenu,
    },
    data() {
        return {
            admin_menu : [
                    { title : '대시보드', icon : 'home', route:"/admin/app/school/contents/dashboard", is_selected: false,
                        link : '/admin/app/school/contents/dashboard', children: [] },
                    { title : '학습설계', icon : 'o_location_city', route:"/admin/app/school/contents/course", is_selected: false,
                        link : '/admin/app/school/contents/course', children: [] },
                    // { title : '문제출제', icon : 'o_location_city', route:"/admin/app/school/contents/problem", is_selected: false,
                    //     link : '/admin/app/school/contents/problem', children: [] },
                        
            ],
        }
    },
    computed : {
        admin() {
            return this.$store.state.admin;
        },
    },
    methods : {
        leftMenu_click : function(route) {
            var i;
            for(i=0; i<this.admin_menu.length; i++) {
                if( route.startsWith(this.admin_menu[i].route)) {
                    if( this.admin_menu[i].is_selected==true ) {
                        this.admin_menu[i].is_selected = false;
                    } else {
                        this.admin_menu[i].is_selected = true;
                    }
                } else {
                    this.admin_menu[i].is_selected = false;
                }
            }
        },
        goto_link : function(menu) {
            if(menu.children.length==0 && menu.link) {
                location.href = menu.link;
            }
        },
    },
    
    beforeMount() {
        this.leftMenu_click(window.location.pathname);
    },
}
</script>

<style scope>
.q-item__section--avatar {
    min-width: 40px;
}
.q-expansion-item.active {
    background-color:#333;
}
.q-expansion-item.left_menu > .q-expansion-item__container > .q-item {
    min-height:36px;
}
</style>
